import React, { useEffect, useState } from "react";
import { CategoryMenu } from "./CategoryMenu";
import bot from "../icons/bot.json";
import brain from "../icons/halfbrain.json";
import cel from "../icons/cel.json";
import site from "../icons/site.json";
import { LottieIcon } from "./LottieIcon";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";

export const InitialView = ({ opciones }) => {
  const {
    vnConfiguracion: viewName,
    vfConfigVoicebots,
    vfConfigAuditorias,
    vfConfigLlamados,
    vfConfigSitio,
  } = viewTypes;

  const categories = [
    {
      category: "site",
      title: "Configuración Sitio",
      icon: site,
      path: "/config-site",
      visible: ShowView(vfConfigSitio, viewName),
    },
    {
      category: "calls",
      title: "Configuración Llamados",
      icon: cel,
      path: "/config-calls",
      visible: ShowView(vfConfigLlamados, viewName),
    },
    {
      category: "bot",
      title: "Configuración Voicebots",
      icon: bot,
      path: "/config-voicebots",
      visible: ShowView(vfConfigVoicebots, viewName),
    },
    {
      category: "audit",
      title: "Configuración Auditorías",
      icon: brain,
      path: "/config-audits",
      visible: ShowView(vfConfigAuditorias, viewName),
    },
  ];

  const [actualCategory, setActualCategory] = useState(
    sessionStorage.getItem("categoryView") || "site"
  );

  useEffect(() => {
    sessionStorage.removeItem("categoryView");
  }, []);

  const goConfig = (option, category) => {
    const path =
      categories.find((cat) => cat.category === category)?.path ??
      "/configuration";

    sessionStorage.setItem("optionView", option);
    sessionStorage.setItem("categoryView", category);
    window.location.href = path;
  };

  return (
    <>
      {actualCategory && (
        <CategoryMenu
          categories={categories}
          actualCategory={actualCategory}
          setActualCategory={setActualCategory}
        />
      )}
      <div className="initial-view">
        {opciones.map(
          (op) =>
            actualCategory === op.category && (
              <ItemView key={op.label} data={op} goConfig={goConfig} />
            )
        )}
      </div>
    </>
  );
};

const ItemView = ({ data, goConfig }) => {
  return (
    <button
      className="item-view"
      onClick={() => goConfig(data.view, data.category)}
    >
      <div className="icon">
        <LottieIcon icon={data.icon} height={50} width={50} />
      </div>
      <div className="text-info">
        <div className="text-title">{data.label}</div>
        <div className="text-desciption">{data.description}</div>
      </div>
    </button>
  );
};
